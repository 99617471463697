"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var stimulus_1 = require("stimulus");
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.connect = function () {
        this.storeRefCodeInSessionStorage();
        this.setTrackingInfo();
    };
    default_1.prototype.storeRefCodeInSessionStorage = function () {
        var queryString = window.location.search;
        var params = new URLSearchParams(queryString);
        var refCode = params.get('ref_code');
        var oldRefCode = sessionStorage.getItem('ref_code');
        if (refCode && oldRefCode === null) {
            sessionStorage.setItem('ref_code', refCode);
        }
    };
    default_1.prototype.setTrackingInfo = function () {
        var cookieData = {
            ref_code: sessionStorage.getItem('ref_code'),
            time: new Date().toISOString()
        };
        document.cookie = "tracking_info=".concat(JSON.stringify(cookieData), "; path=/;");
    };
    return default_1;
}(stimulus_1.Controller));
exports.default = default_1;
