import {makeHypernovaComponents} from '@hikakaku/shared/util/hypernova'

import ShopListSelectPrefecture from './shops/shoplist_select_prefecture'
import RankingListSingleProductContainers from './single_products/ranking_list_single_product_containers'
import ItemSearchResult from './search/item_search_result'
import BlogSearchResult from './search/blog_search_result'
import BlogSearchResultSmartPhone from './search/blog_search_result_smartphone'
import EstimateBannerLinkWithImage from './estimate_banners/link_with_image'
import EstimateBannerFormWithImage from './estimate_banners/form_with_image'
import GoogleMapContainer from '~/components/maps/locations/'
import HikakakuReviewForm from '~/components/forms/review_form/hikakaku_review_form'
import OtherRequestListContainer from '~/components/users/estimates/requests/messages/other_request_list/other_request_list_cntainer'
import SellingAgreementButton from '~/components/users/estimates/requests/messages/selling_agreement_button'
import HeaderNaviItemCompany from '~/shared/header/navi_item_company'
import BrandList from '~/components/category_lp/BrandList'
import BlogCommentList from '~/components/blog/blog_comment_list'
import BlogCommentForm from '~/components/forms/blog_comment_form'
import EstimatePreEntryForm from '~/components/root/EstimatePreEntryForm'
import CompanyItemMiniNoPriceList from '~/components/company/item_mini_no_price/List'
import KaitoriHistoryItems from '~/components/single_products/kaitori_history_items'
import KaitoriHistoryItemsMini from '~/components/single_products/kaitori_history_items_mini'
import ReviewUserFeedbackButton from '~/components/reviews/UserFeedbackButton'
import BlogSupervisorCarousel from '~/components/blog/supervisor_carousel/List'
import CategoryFaqList from '~/components/category/faq/List'
import CategoryLpEstimateForm from '~/components/category_lp/EstimateForm'

export default makeHypernovaComponents({
  ShopListSelectPrefecture,
  RankingListSingleProductContainers,
  ItemSearchResult,
  BlogSearchResult,
  BlogSearchResultSmartPhone,
  EstimateBannerLinkWithImage,
  EstimateBannerFormWithImage,
  GoogleMapContainer,
  HikakakuReviewForm,
  OtherRequestListContainer,
  SellingAgreementButton,
  HeaderNaviItemCompany,
  BrandList,
  BlogCommentList,
  BlogCommentForm,
  EstimatePreEntryForm,
  CompanyItemMiniNoPriceList,
  KaitoriHistoryItems,
  KaitoriHistoryItemsMini,
  ReviewUserFeedbackButton,
  BlogSupervisorCarousel,
  CategoryFaqList,
  CategoryLpEstimateForm
})
